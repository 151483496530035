import { useState, useEffect } from 'react';
import axios from 'axios';

import { Spinner } from '../..';

const START_TIME_FIELD = 'a1b81d5a6a8fa7ba55bb15ae54df9f2faa89747f';

const LOCATION_FIELD = '9ee6c2b59c25f7d7b1aa3c89b73ba9dce405d33e';

const TIME_FIELD = '804364266aee78ee4bf9cdc399b69b3caa3cf3c7';

const TOKEN = 'e0425935e9df3967eb4791c2f8c5fa569d73b9b0';

const Time = ({ bookingData, setBookingData, slots, date, location_id }) => {
  const URL = `https://wefix-appliance.pipedrive.com/api/v1/deals/timeline?start_date=${
    date.split('T')[0]
  }&interval=day&amount=1&field_key=${START_TIME_FIELD}&api_token=${TOKEN}`;

  const [deals, setDeals] = useState();
  const [availableSlots, setAvailableSlots] = useState();

  const handleChange = (val, time) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingTime: val,
      time,
    }));
  };

  const getDateData = async () => {
    try {
      const response = await axios.get(URL);

      if (response.status === 200) {
        setDeals(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDateData();
  }, []);

  useEffect(() => {
    if (deals) {
      let slotsData = [];

      slots.map((el) => {
        const dealsWithSlot = deals[0].deals.filter(
          (deal) => deal[TIME_FIELD] === el.pipedrive_slot_value && deal[LOCATION_FIELD] === location_id
        );

        if (dealsWithSlot) {
          if (!Boolean(dealsWithSlot.length >= Number(el.max_amount_per_day)))
            slotsData.push({
              slot_value: el.pipedrive_slot_value,
              title: el.time,
            });
        }
      });

      setAvailableSlots(slotsData);
    }
  }, [deals]);

  if (!deals || !availableSlots) return <Spinner />;

  return (
    <fieldset className="step-wrapper">
      {availableSlots?.map((el, index) => (
        <div
          className={`slot-wrapper ${bookingData.time === el.title ? 'active' : 'inactive'}`}
          key={index}
          onClick={() => handleChange(el.slot_value, el.title)}
        >
          <span>{el.title}</span>
        </div>
      ))}

      {!availableSlots.length && <p className="no-slots">No slots available, please select another date</p>}
    </fieldset>
  );
};

export default Time;
